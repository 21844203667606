import React, { useContext, useState, useEffect } from "react";

import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete";

import { InputNumber } from "primereact/inputnumber";
import "../styles/fieldCecos.css";
import TableCecos from "./TableCecos";
import { NovedadesContext } from "../contexts/NovedadesContext";

const FieldCecos = ({
  calcularPorcentaje,
  arrayCecos,
  setArrayCecos,
  errorPorcentaje,
  verSoloLectura,
  novedadTipo,
}) => {
  const { getCecos, cecos } = useContext(NovedadesContext);
  const [cecosArray, setCecosArray] = useState();
  const [tableValueCecos, setTableValueCecos] = useState([]);
  const [porcentaje, setPorcentaje] = useState();
  const [filteredCecos, setFilteredCecos] = useState();

  const handleClick = (e) => {
    e.preventDefault();
    arrayCecos?.push({
      id: cecosArray.IdCecos,
      codigo: cecosArray.Codigo,
      cliente: cecosArray.Cliente,
      proyecto: cecosArray.Proyecto,
      porcentaje: porcentaje,
    });
    calcularPorcentaje();
    const valueIdCecos = cecosArray.IdCecos;
    setTableValueCecos([...tableValueCecos, valueIdCecos]);

    let valueCecosPorcentaje = 0;
    for (let i = 0; i < arrayCecos?.length; i++) {
      valueCecosPorcentaje += arrayCecos[i].porcentaje;
    }
    errorPorcentaje(valueCecosPorcentaje);
    if (valueCecosPorcentaje > 100) {
      arrayCecos.pop();
      calcularPorcentaje();
    } else {
      setCecosArray("");
      setPorcentaje();
    }
  };

  useEffect(() => {
    getCecos();
    // setArrayCecos([]);
  }, []);

  const searchCecos = (event) => {
    let _filteredCecos;

    if (!event.query.trim().length) {
      _filteredCecos = [...cecos];
    } else {
      _filteredCecos = cecos.filter((p) => {
        return p.Descripcion.toLowerCase().includes(event.query.toLowerCase());
      });
    }

    if (tableValueCecos) {
      for (let i = 0; i < arrayCecos?.length; i++) {
        const idTableValueCecos = arrayCecos[i];
        const found = _filteredCecos.filter(
          (dato) => dato.IdCecos !== idTableValueCecos.id
        );
        _filteredCecos = found;
      }
    }

    setFilteredCecos(_filteredCecos);
  };

  return (
    <>
      <h4 className="form-subtitle">Centro de Costos</h4>
      <div className="row-container cont-cecos">
        <div className="input-container  medium">
          <span className="p-float-label">
            <AutoComplete
              id="cecos"
              dropdown
              value={cecosArray}
              suggestions={filteredCecos}
              completeMethod={searchCecos}
              field="Descripcion"
              forceSelection
              onChange={(e) => {
                setCecosArray(e.value);
              }}
              disabled={verSoloLectura}
            />
            <label htmlFor="cecos">Centro de Costos*</label>
          </span>
        </div>
        <div className="input-container">
          <span className="p-float-label">
            <InputNumber
              inputId="porcentaje"
              autoComplete="off"
              value={porcentaje}
              onValueChange={(e) => setPorcentaje(e.value)}
              min={0}
              max={100}
              disabled={verSoloLectura}
            />
            <label htmlFor="porcentaje">Porcentaje*</label>
          </span>
        </div>
        <div className="input-container small">
          <Button
            disabled={(!porcentaje || !cecos) && true}
            onClick={(e) => handleClick(e)}
            type="submit"
            label="Agregar"
            className="short-button"
          />
        </div>
      </div>
      <TableCecos
        setTableValueCecos={setTableValueCecos}
        calcularPorcentaje={calcularPorcentaje}
        arrayCecos={arrayCecos}
        setArrayCecos={setArrayCecos}
        verSoloLectura={verSoloLectura}
        novedadTipo={novedadTipo}
      />
    </>
  );
};

export default FieldCecos;
